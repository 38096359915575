<div class="modal solid open" id="NetworkV2InfoModal">
    <div (click)="close()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="title">Network Firewall Info</div>
    <div class="icon" style="background-image: url(/assets/svgs/EditNetwork.svg)"></div>
    <div class="subtitle">To avoid firewall conflicts make sure the following OUTBOUND attributes are whitelisted.</div>

    <div class="row" style="overflow: auto; height: 18.75rem; margin-top: 2.5rem; padding-top: 0rem">
        <div class="row">
            For VM registration, please ensure you have whitelisted the domains and port information listed in our
            <a
                href="https://support.netfoundry.io/hc/en-us/articles/4402361752717-Firewall-Requirements"
                target="_blank"
                >Support Documentation.</a
            ><br />
        </div>

        <!-- headers -->
        <div class="table four">
            <div class="header borderBottom borderRight">Type</div>
            <div class="header borderBottom borderRight">IP Address</div>
            <div class="header borderBottom">DNS Name</div>
            <div class="header borderBottom">Port</div>
        </div>

        <!-- rows -->
        <div *ngFor="let item of items" class="table four">
            <div class="header borderBottom borderRight">{{ item.type }}</div>
            <div class="borderBottom borderRight">
                <span *ngFor="let ip of item.ips" class="itemIp" style="display: block"
                    ><button
                        (click)="clickToCopy(ip)"
                        class="p-button-text"
                        icon="pi pi-copy"
                        pButton
                        type="button"
                    ></button>
                    {{ ip }}
                </span>
            </div>
            <div class="borderBottom borderRight">
                <span *ngFor="let domainName of item.domainNames" class="itemIp" style="display: block"
                    ><button
                        (click)="clickToCopy(domainName)"
                        class="p-button-text"
                        icon="pi pi-copy"
                        pButton
                        type="button"
                    ></button>
                    {{ domainName }}
                </span>
            </div>
            <div class="borderBottom">
                <span *ngFor="let port of item.port" class="itemIp" style="display: block"
                    ><button
                        (click)="clickToCopy(port)"
                        class="p-button-text"
                        icon="pi pi-copy"
                        pButton
                        type="button"
                    ></button>
                    {{ port }}
                </span>
            </div>

        </div>
    </div>

    <div class="buttons">
        <div (click)="csv_export()" class="save clear" id="ExportToCsv">Export To CSV</div>
    </div>

    <div buttons>
        <span class="versionInfo"> Network Version: {{ majorVersionNumber }} </span>
    </div>

    <div class="buttons">
        <button (click)="close()" class="save" id="CloseNetworkInfoButton">OK Thanks</button>
    </div>
</div>
