import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Environment, ENVIRONMENT, BrowzerBootstrapper } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';
import {
    GetOption,
    HateoasResourceOperation,
    PagedGetOption,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import { TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { ZitiControllerService } from './ziti-controller.service';
import { ZITI_DOMAIN_CONTROLLER } from 'ziti-console-lib';
import { NetworkServiceV2 } from './network.service';

@Injectable({ providedIn: 'root' })
export class BrowzerBootstrapperService extends HateoasResourceOperation<BrowzerBootstrapper> {
    static defaultPaginationSize = 500;
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;
    ztSession;
    domain;
    apiUrl: string;
    constructor(
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: Environment,
        private filterService: TableFilterService,
        @Inject(ZITI_DOMAIN_CONTROLLER) private zitiController: ZitiControllerService,
        public networkService: NetworkServiceV2

    ) {
        super(BrowzerBootstrapper);
        this.apiUrl = environment.v3Enabled ? environment.v3apiUrl : environment.v2apiUrl;
    }
    getResource(): Observable<BrowzerBootstrapper> {
        throw new Error('Do not use: see get getBrowzerBootstappers');
    }

    getPage(): Observable<PagedResourceCollection<BrowzerBootstrapper>> {
        throw new Error('Do not use: see getBrowzerBootstrappers');
    }

    getBrowzerBootstrapper(id, options: GetOption = {}): Promise<BrowzerBootstrapper> {
        return super
            .getResource(id, { ...BrowzerBootstrapperService.defaultHttpAccept, ...options })
            .toPromise()
            .then((service) => service);
    }



    async getBrowzerBootstrappersPage(options: PagedGetOption, includeProperties?: string): Promise<BrowzerBootstrapper[]> {
        let params = { ...BrowzerBootstrapperService.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super
            .getPage(params)
            .pipe(
                tap((val) => {
                    this.filterService.setPageSize(val.pageSize);
                    this.filterService.setTotalPages(val.totalPages);
                    this.filterService.setTotalElements(val.totalElements);
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((browzerBootstrappers) => browzerBootstrappers.resources);
    }

    async getAllBrowzerAppsByNetworkId(networkId: string, options = {}, includeProps = ''): Promise<BrowzerBootstrapper[]> {
        const size = 1;
        let params: PagedGetOption = {
            params: { networkId },
            pageParams: { size, page: 0 },
            sort: { name: 'ASC' },
            headers: { accept: 'application/hal+json' },
        };
        params = { ...params, ...options };

        if (includeProps) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProps}` } };
        }
        let finalResults = [];
        return this.getBrowzerBootstrappersPage(params).then((results) => {
            finalResults = results;
            if (this.lastTotalCount > 1) {
                finalResults = [];
                const promises = [];
                let p = 0;
                const totalPages = Math.ceil(this.lastTotalCount / BrowzerBootstrapperService.defaultPaginationSize);
                params.pageParams.size = BrowzerBootstrapperService.defaultPaginationSize;
                while (p < totalPages) {
                    params.pageParams.page = p++;
                    promises.push(this.getBrowzerBootstrappersPage(params));
                }
                return Promise.all(promises).then((promiseResults) => {
                    promiseResults.forEach((r) => {
                        finalResults = finalResults.concat(r);
                    });
                    return finalResults;
                });
            } else {
                return finalResults;
            }
        });
    }

    public validateUpdate(id: string, model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .patch(this.apiUrl + 'browzer-bootstrappers/' + id, model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public async validateCreate(model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .post(this.apiUrl + 'browzer-bootstrappers', model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public getJson(id: string): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}browzer-bootstrappers/${id}/?meta=ziti,diffZiti`;
        return this.http.get(url, options) as Observable<any>;
    }

    public restart(id: string): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}browzer-bootstrappers/${id}/restart`;
        return this.http.post(url, options) as Observable<any>;
    }
    public resume(id: string): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}browzer-bootstrappers/${id}/resume`;
        return this.http.post(url, options) as Observable<any>;
    }

    public suspend(id: string): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}browzer-bootstrappers/${id}/suspend`;
        return this.http.post(url, options) as Observable<any>;
    }

    public resize(id: string, size: string, expectedCount: string): Observable<string> {
        const options: any = {};
        const body: any = { size, expectedCount};
        const url = `${this.apiUrl}browzer-bootstrappers/${id}/resize`;
        return this.http.post(url, body, options) as Observable<any>;
    }

    public getContainerSizes(): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}container-sizes`;
        return this.http.get(url, options) as Observable<any>;
    }


    public upgrade(id: string, tag: string): Observable<string> {
        const options: any = { };
        const body: any = { tag }
        const url = `${this.apiUrl}browzer-bootstrappers/${id}`;
        return this.http.put(url, body, options) as Observable<any>;
    }

    public downloadFileFormat(
        networkId: string,
        format = 'text/csv',
        params: HttpParams = new HttpParams()
    ): Observable<any> {
        params = params.set('networkId', networkId);
        const headers = new HttpHeaders().set('Accept', format);
        return this.http
        .get(this.apiUrl + 'browzer-bootstrappers?embed=config', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }
}
