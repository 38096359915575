<app-loader *ngIf="isLoading" [isLoading]="isLoading" class="load-indicator" id="zitiEndpointsPage"></app-loader>
<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="ZitiEndpointAddModal">
    <div [hidden]="isComplete" class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span *ngIf="!isEditing"
                >Create A New {{ featureService?.disableNewNavigation ? 'Endpoint' : 'Identity' }}</span
            >
            <span *ngIf="isEditing"
                >Edit Your {{ featureService?.disableNewNavigation ? 'Endpoint' : 'Identity' }}</span
            >
        </div>
        <div *ngIf="!isEditing" class="subtitle">
            Enter your {{ featureService?.disableNewNavigation ? 'endpoint' : 'identity' }} details
        </div>
        <div *ngIf="isEditing" class="subtitle">
            Edit your {{ featureService?.disableNewNavigation ? 'endpoint' : 'identity' }} details
        </div>
        <div
            class="subhelp"
            matTooltip="An enrolled {{
                featureService?.disableNewNavigation ? 'endpoint' : 'identity'
            }} may host or access a service. A tunneler is a special type of {{
                featureService?.disableNewNavigation ? 'endpoint' : 'identity'
            }} that tethers clients or servers or both to the network. For example, Mobile Edge app and Desktop Edge app are tunnelers for other apps, such as a web browser, running on your device."
            matTooltipPosition="below"
        >
            What's an {{ featureService?.disableNewNavigation ? 'Endpoint' : 'Identity' }}?
        </div>
        <div class="sized">
            <div class="label row">
                {{ featureService?.disableNewNavigation ? 'Endpoint' : 'Identity' }} Name
                <div class="step">1</div>
                <span [hidden]="errors['name']" class="note">Required</span>
            </div>
            <div class="row input">
                <input
                    (change)="validate()"
                    (keyup.enter)="save(true)"
                    [(ngModel)]="model.name"
                    [disabled]="!canEdit || isTunnelEnabled"
                    [ngClass]="{ error: errors['name'] }"
                    class="ep-name-input"
                    id="EditZRouterName"
                    placeholder="Name this {{ featureService?.disableNewNavigation ? 'endpoint' : 'identity' }}"
                    type="text"
                />
                <span [hidden]="!errors['name']" class="err">
                    {{ errors['name'] }}
                </span>
            </div>

            <div *ngIf="certificateAuthorities.length > 0 && !isEditing">
                <div [ngClass]="{ error: errors['enrollmentMethod'] }" class="label row">
                    Enollment Method
                    <span class="note">Required</span>
                </div>
                <div class="row">
                    <select
                        [(ngModel)]="enrollmentMethod"
                        [disabled]="isEditing"
                        [ngClass]="{ error: errors['enrollmentMethod'] }"
                        id="EnrollmentTypeList"
                    >
                        <option value="OTT">One Time Token</option>
                        <option value="OTTCA">Certificate Authority</option>
                    </select>
                </div>
            </div>

            <div *ngIf="enrollmentMethod === 'OTTCA'">
                <div [ngClass]="{ error: errors['enrollmentMethodOTTCA'] }" class="label row">
                    Choose a CA
                    <span class="note">Required</span>
                </div>
                <div class="row">
                    <select
                        [(ngModel)]="selectedCA"
                        [disabled]="isEditing"
                        [ngClass]="{ error: errors['enrollmentMethodOTTCA'] }"
                        id="CAs"
                    >
                        <option *ngFor="let ca of certificateAuthorities | sortby: 'name'" [value]="ca.id" value="">
                            {{ ca.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="label row">
                {{ featureService?.disableNewNavigation ? 'Endpoint' : 'Identity' }} Attributes
                <div
                    class="infoicon"
                    matTooltip="{{
                        featureService?.disableNewNavigation ? 'Endpoint' : 'Identity'
                    }} Attributes are tags applied to a {{
                        featureService?.disableNewNavigation ? 'Endpoint' : 'Identity'
                    }}. Apply the same tag to other services to form a group of {{
                        featureService?.disableNewNavigation ? 'Endpoints' : 'Identities'
                    }}."
                    matTooltipPosition="below"
                ></div>
                <div class="step">2</div>
                <span class="note">Optional</span>
            </div>
            <div class="row ep-attributes-row">
                <app-tags-selector
                    (addAttribute)="addEndpointsAttributes($event)"
                    (removeAttribute)="removeEndpointAttribute($event)"
                    [availableAttributes]="endpointAttributes"
                    [disableField]="isEditing && !canEdit"
                    [errorMessage]="errors['attributes']"
                    [hasError]="attributeError"
                    [isLoading]="isLoadingEndpointAttr"
                    [placeholder]="
                        'Add attributes to group ' + (featureService?.disableNewNavigation ? 'endpoints' : 'identities')
                    "
                    [selectedAttributes]="selectedEndpointAttributes"
                    id="Endpoints"
                ></app-tags-selector>
            </div>

            <div *ngIf="(canListUsers || canCreateUsers) && (requireAuthSession || hadRequireAuthSession)" class="row">
                <div class="label row labelExpansion">
                    Require Netfoundry Authorization Session
                    <div
                        class="infoicon"
                        matTooltip="In addition to the enrolled identity; require an active, concurrent login session in the NF console to activate this {{
                            featureService?.disableNewNavigation ? 'endpoint' : 'identity'
                        }}."
                        matTooltipPosition="below"
                    ></div>
                    <div class="step">3</div>
                    <span class="note">Optional</span>
                </div>
                <div class="row">
                    <mat-accordion>
                        <mat-expansion-panel #panel [expanded]="requireAuthSession" [hideToggle]="true">
                            <mat-expansion-panel-header (click)="toggleRequireAuth()" [collapsedHeight]="'4.0625rem'">
                                <mat-panel-title class="expansionLabel">
                                    <div class="title">
                                        <div
                                            class="icon-NFAuthorizationSessionManagement"
                                            style="margin-top: -1px"
                                        ></div>
                                    </div>
                                    <div class="subtitle">Require NetFoundry Authorization Session</div>
                                    <div>
                                        <div class="switchGrid" style="margin-top: 1.25rem">
                                            <div>
                                                <div [ngClass]="{ on: requireAuthSession }" class="toggle">
                                                    <span [hidden]="!requireAuthSession">ON&nbsp;&nbsp;&nbsp;</span>
                                                    <span [hidden]="requireAuthSession"
                                                        >&nbsp;&nbsp;&nbsp;&nbsp;OFF</span
                                                    >
                                                    <div class="switch"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-grid-list *ngIf="requireAuthSession" cols="10" rowHeight="6.25rem">
                                <mat-grid-tile [colspan]="3">
                                    <div [ngClass]="{ error: errorAuthUser }" class="label gridTileLabel">
                                        Select a User
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="7">
                                    <div style="width: 100%">
                                        <app-nf-select2
                                            (selectionChange)="onChangeAuthUserIdentityId($event)"
                                            [hasError]="errorAuthUser"
                                            [isFullWidth]="true"
                                            [options]="users"
                                            [placeholder]="'Select a User'"
                                            [selectOne]="true"
                                            [selected]="userSelected"
                                            [usingCustomSort]="true"
                                            id="UserSelector"
                                        ></app-nf-select2>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile *ngIf="addingNewUser" [colspan]="3">
                                    <div [ngClass]="{ error: errorEmail }" class="label gridTileLabel">
                                        Enter Email Address
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile *ngIf="addingNewUser" [colspan]="7">
                                    <input
                                        [(ngModel)]="newUserEmail"
                                        [ngClass]="{ error: errorEmail }"
                                        class="expansionPanelField"
                                        data-private
                                        id="EditEmail"
                                        placeholder="Enter an email address"
                                        type="text"
                                    />
                                </mat-grid-tile>
                            </mat-grid-list>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>

            <div *ngIf="model.jwt" class="sized">
                <div *ngIf="!jwtExpired" class="editsubtitle">
                    Register this {{ featureService?.disableNewNavigation ? 'endpoint' : 'identity' }} in two simple
                    steps.
                </div>
                <div *ngIf="!jwtExpired" class="steptext">
                    <div *ngIf="!isEditing && model.jwt" class="step">1</div>
                    <div *ngIf="model.jwt && isEditing && !jwtExpired" class="step">3</div>
                    Download Your Registration Key
                </div>
                <div class="row" style="margin-top: 1.25rem">
                    <div (click)="downloadJWT()" *ngIf="!jwtExpired" class="downloadButton">
                        <div class="DownloadKey"></div>
                        <div>DOWNLOAD KEY</div>
                        <div class="TapToDownload"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="model.jwt">
                <div
                    *ngIf="!jwtExpired"
                    class="label row"
                    style="text-align: center; margin-top: 0.625rem; margin-bottom: 0.3125rem"
                >
                    OR
                </div>
                <div *ngIf="!jwtExpired" class="label row" style="text-align: center; margin-top: 0rem">
                    Scan Client Registration Key QR Code
                </div>

                <div *ngIf="!jwtExpired" class="qr-code-container">
                    <qrcode [level]="'M'" [qrdata]="model.jwt" [size]="300"></qrcode>
                </div>
                <div *ngIf="!jwtExpired" class="label row" style="text-align: center; color: red; padding: 0.625rem">
                    Expires {{ jwtExpiration }}
                </div>
                <div (click)="reissueJWT()" *ngIf="jwtExpired" class="downloadButton">
                    <div class="DownloadKey"></div>
                    <div>REISSUE KEY</div>
                    <div class="TapToDownload"></div>
                </div>
            </div>

            <div *ngIf="model.jwt">
                <div class="steptext">
                    <div *ngIf="model.jwt && !isEditing" class="step">2</div>
                    <div *ngIf="model.jwt && isEditing" class="step">4</div>
                    <div *ngIf="jwtExpired && isEditing" class="step">4</div>
                    Select an Installer for Your OS
                </div>
            </div>

            <div *ngIf="model.jwt" class="row center" style="margin-top: 1.25rem">
                <div class="row">
                    <div (click)="openDownloads()" class="downloadInstallerButton">
                        <div class="DownloadOS"></div>
                        <div>SELECT AN INSTALLER</div>
                        <div class="DownloadInstaller"></div>
                    </div>
                </div>
            </div>

            <div class="label row">
                Authorization Policy
                <div class="infoicon" [matTooltip]="authPolicyTooltip" matTooltipPosition="below"></div>
                <span class="note">Optional</span>
            </div>
            <div class="darkBox" >
                <div class="mb16">
                    <select [(ngModel)]="model.authPolicyId" id="authPolicyId">
                        <option value="">Select an Authentication Policy</option>
                        <option *ngFor="let policy of authPolicies" [value]="policy.id">
                            {{ policy.name }}
                        </option>
                    </select>
                </div>
                <div class="row input">
                    <input
                        (change)="validate()"
                        (keyup.enter)="save(true)"
                        [(ngModel)]="model.externalId"
                        placeholder="Optional External ID"
                        type="text"
                    />
                </div>
                <span [hidden]="!errors['externalId']" class="err">
                    {{ errors['externalId'] }}
                </span>
            </div>
            <div  *ngIf="isEditing && this.showControllerDomain"class="label row">
                JWT Based Authentication Domain
                <div class="infoicon" [matTooltip]="publicDomainUrl" matTooltipPosition="below"></div>
            </div>
            <div (click)="copyPublicDomainUrl()"  *ngIf="isEditing && this.showControllerDomain" class="label row jwtBased">
              <span>{{ this.publicDomainUrl }}</span> <div class="copy-button" (click)="copyPublicDomainUrl()"></div>
            </div>

            <div class="row buttonRow right" style="margin-top: 1.25rem">
                <button
                    (click)="save()"
                    *ngIf="!processing && (!isEditing || canEdit)"
                    class="save"
                    id="SaveZitiEndpointButton"
                >
                    {{ bulkEdit ? 'Done' : isEditing ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>

            <div *ngIf="isEditing" class="associated row">
                <div class="grid half thin">
                    <app-preview-list
                        (itemSelected)="serviceSelected($event)"
                        [allNames]="servicePreviewList"
                        [clickable]="!isInline"
                        [isLoading]="isLoadingAssocServices"
                        label="Associated Services"
                        (displayPostProcess)="postProcessDisplay($event)"
                    ></app-preview-list>
                    <app-preview-list
                        (itemSelected)="appwanSelected($event)"
                        [allNames]="appwans"
                        [clickable]="!isInline"
                        [isLoading]="isLoadingAssocAppwans"
                        label="Associated {{ featureService?.disableNewNavigation ? 'AppWans' : 'Service Policies' }}"
                    ></app-preview-list>
                </div>
            </div>

            <div *ngIf="isRouterManaged" class="row" style="padding-bottom: 1.5rem">
                <div class="managed">
                    This {{ featureService?.disableNewNavigation ? 'Endpoint' : 'Identity' }} is system managed by the
                    following Edge Router:
                    <a (click)="edgeRouterSelected(managedRouters[0])"> {{ managedRouters[0] }}</a>
                </div>
            </div>
        </div>
    </div>

    <div [hidden]="!isComplete" class="fullRow" id="Stage3">
        <div class="title" style="filter: brightness(50%)">Congratulations</div>
        <div class="subtitle">
            Your {{ featureService?.disableNewNavigation ? 'Endpoint' : 'Identity' }} {{ completedTitleStatusString }}
        </div>
        <div [ngClass]="{ building: isBuilding, created: !isBuilding }" class="row center">
            <span *ngIf="isBuilding" class="completedEndpointBubble">
                <svg class="circular" viewBox="25 25 50 50">
                    <circle
                        class="path"
                        cx="50"
                        cy="50"
                        fill="none"
                        r="20"
                        stroke-miterlimit="10"
                        stroke-width="5"
                    ></circle>
                </svg>
            </span>
        </div>

        <div *ngIf="model.jwt" class="sized">
            <div class="editsubtitle">
                Register this {{ featureService?.disableNewNavigation ? 'Endpoint' : 'Identity' }} in two simple steps.
            </div>
            <div class="steptext">
                <div class="step">1</div>
                Download Your Registration Key
            </div>
            <div class="row" style="margin-top: 1.25rem">
                <div (click)="downloadJWT()" class="downloadButton downloadJwtButton">
                    <div class="DownloadKey"></div>
                    <div>DOWNLOAD KEY</div>
                    <div class="TapToDownload"></div>
                </div>
            </div>
        </div>

        <div *ngIf="model.jwt" class="sized">
            <div class="label row" style="text-align: center; margin-top: 0.625rem; margin-bottom: 0.3125rem">OR</div>
            <div class="label row" style="text-align: center; margin-top: 0rem">
                Scan Client Registration Key QR Code
            </div>
            <div class="qr-code-container">
                <qrcode [level]="'M'" [qrdata]="model.jwt" [size]="300"></qrcode>
            </div>
        </div>

        <div *ngIf="model.jwt" class="sized">
            <div class="steptext">
                <div class="step">2</div>
                Select an Installer for Your OS
            </div>
        </div>

        <div *ngIf="model.jwt" class="row center" style="margin-top: 1.25rem; margin-bottom: 1.25rem; padding-bottom: 3.125rem">
            <div class="sized">
                <div (click)="openDownloads()" class="downloadInstallerButton">
                    <div class="DownloadOS"></div>
                    <div>SELECT AN INSTALLER</div>
                    <div class="DownloadInstaller"></div>
                </div>
            </div>
        </div>
    </div>

    <div (click)="getPostureData()" *ngIf="isEditing" class="buttonBall posture" id="PostureDataButton">
        <div class="buttonLabel">DATA</div>
    </div>

    <div (click)="getEventsData()" *ngIf="isEditing" class="buttonBall events" id="EventsButton">
        <div class="buttonLabel">Events</div>
    </div>

    <div (click)="getMetrics()" *ngIf="isEditing && !model.jwt" class="buttonBall metrics" id="MetricsButton">
        <div class="buttonLabel">METRICS</div>
    </div>
    <div (click)="getDialLogs()" *ngIf="isEditing && !model.jwt" class="buttonBall network-failure" id="DialLogsButton">
        <div class="buttonLabel">Dial Logs</div>
    </div>

    <app-online-status *ngIf="!model.jwt"></app-online-status>

    <div (click)="share()" *ngIf="canShareEndpoint && model.jwt" class="buttonBall share" id="ShareEditClientButton">
        <div class="buttonLabel">SHARE</div>
    </div>
    <div (click)="hide()" class="buttonBall close" id="EscZEndpointClose">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
