import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { catchError, delay } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';

import _ from 'lodash';
import moment from 'moment';

import { HTTP_CLIENT, TokenService } from './token.service';
import { LoggerService } from './logger.service';
import { ApiService } from './api.service';
import { IdentityPreferencesService } from './identity-preferences.service';
import { OrganizationPreferencesService } from './organization-preferences.service';
import { NetworkVersionService } from './network-version.service';
import semver from 'semver';

const WHATS_NEW_ITERATION_KEY = 'nf_whats_new_iteration';

@Injectable({ providedIn: 'root' })
export class FeatureService {
    public experimentalFeatures = false;
    public isCloudZiti = false;

    user;
    apiUrl;
    userPreferences;
    orgPreferences;
    orgPreferencesInit = false;
    organization;
    tenant;
    experimentalFeaturesChanged = new EventEmitter();
    disableNewNavChanged = new EventEmitter();

    minBrowzerVersion = '7.3.90';

    minCloudZitiVersion = 8;
    minCloudZitiMinorVersion = 0;
    minCloudZitiPatchVersion = 0;

    constructor(
        private logger: LoggerService,
        private apiService: ApiService,
        private tokenService: TokenService,
        private identityPreferencesService: IdentityPreferencesService,
        private organizationPreferencesService: OrganizationPreferencesService,
        private networkVersionService: NetworkVersionService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.isCloudZiti = environment.isCloudZiti;
        this.apiUrl = _.get(environment, 'identityConfig.url');
        this.apiService.currentOrg.subscribe((org) => {
            this.organization = org;
            this.checkFeatures(org.getId());
            this.getCurrentUser();
        });

        this.apiService.currentUser.subscribe((user) => {
            if (_.isEmpty(user)) {
                return;
            }
            this.user = user;
        });

        this.apiService.currentTenant.subscribe((tenant) => {
            this.tenant = tenant;
        });
        this.apiService.currentUserPreferences.subscribe((userPreferences) => {
            this.userPreferences = _.cloneDeep(userPreferences?.preferences);
        });
        this.apiService.currentOrgPreferences.subscribe((orgPreferences) => {
            this.orgPreferences = _.cloneDeep(orgPreferences);
            this.orgPreferencesInit = true;
        });
    }

    _whatsNewIteration = 0;

    public get whatsNewIteration() {
        if (!this.user) {
            return 0;
        }
        const storageKey = `whatsNewIteration_${this.user?.id}`;
        const prefKey = 'whatsNewIteration';
        const localIteration = localStorage.getItem(storageKey);
        const localIterationNumber = Number.parseInt(localIteration, 10);
        const prefSetting = _.get(this.userPreferences, prefKey);
        if (!_.isEmpty(prefSetting) || prefSetting === 0) {
            return prefSetting;
        } else if (_.isNumber(localIterationNumber)) {
            return localIterationNumber;
        } else {
            return 0;
        }
    }

    public set whatsNewIteration(iteration) {
        this._whatsNewIteration = iteration;
        const prefKey = `whatsNewIteration`;
        const storageKey = `whatsNewIteration_${this.user?.id}`;
        localStorage.setItem(storageKey, `${iteration}`);
        this.identityPreferencesService.setPreference(prefKey, this._whatsNewIteration);
    }

    _powerUserEnabled;

    public get powerUserEnabled() {
        if (!this.user) {
            return false;
        }

        const localStorageSetting = localStorage.getItem('powerUserEnabled_' + this.user.id) === 'on';
        if (this.userPreferences?.powerUserEnabled === false) {
            return false;
        } else {
            return localStorageSetting || this.userPreferences?.powerUserEnabled;
        }
    }

    _enableNewNavigation = false;

    public get enableNewNavigation() {
        if (!this.user) {
            return false;
        }
        const storageKey = `enableNewNavigation_${this.user?.id}`;
        const prefKey = 'enableNewNavigation';
        const localStorageSetting = localStorage.getItem(storageKey) === 'true';
        const prefSetting = _.get(this.userPreferences, prefKey);
        if (prefSetting === false) {
            return false;
        } else {
            return localStorageSetting || prefSetting;
        }
    }

    public set enableNewNavigation(enabled) {
        this._enableNewNavigation = enabled;
        const enableStorageKey = `enableNewNavigation_${this.user?.id}`;
        const enablePrefKey = 'enableNewNavigation';
        const disableStorageKey = `newNavigationDisabled_${this.user?.id}`;
        const disablePrefKey = 'newNavigationDisabled';
        if (this._enableNewNavigation) {
            localStorage.setItem(enableStorageKey, 'true');
            localStorage.setItem(disableStorageKey, 'false');
        } else {
            localStorage.setItem(enableStorageKey, 'false');
            localStorage.setItem(disableStorageKey, 'true');
        }
        const keyValuePairs = [
            {
                key: enablePrefKey,
                value: enabled,
            },
            {
                key: disablePrefKey,
                value: !enabled,
            },
        ];
        this.identityPreferencesService.setPreferences(keyValuePairs);
    }

    _disableNewNavigation;

    public get disableNewNavigation() {
        if (!this.enableNewNavigation) {
            return true;
        }
        if (!this.user) {
            return false;
        }

        const storageKey = `newNavigationDisabled_${this.user?.id}`;
        const prefKey = 'newNavigationDisabled';
        const localStorageSetting = localStorage.getItem(storageKey) === 'true';
        const prefSetting = _.get(this.userPreferences, prefKey);
        if (prefSetting === false) {
            return false;
        } else {
            return localStorageSetting || prefSetting;
        }
    }

    public set disableNewNavigation(disabled) {
        this._disableNewNavigation = disabled;
        const prefKey = `newNavigationDisabled`;
        const storageKey = `newNavigationDisabled_${this.user?.id}`;
        if (this._disableNewNavigation) {
            localStorage.setItem(storageKey, 'true');
        } else {
            localStorage.setItem(storageKey, 'false');
        }
        this.identityPreferencesService.setPreference(prefKey, this._disableNewNavigation);
        this.disableNewNavChanged.emit(disabled);
    }

    _showGettingStarted;

    public get showGettingStarted() {
        const localStorageSetting = localStorage.getItem(`showGettingStarted_${this.organization?.name}`) === 'true';
        if (this.userPreferences?.showGettingStarted === false) {
            return false;
        } else {
            return localStorageSetting || this.userPreferences?.showGettingStarted;
        }
    }

    public set showGettingStarted(hide) {
        this._showGettingStarted = hide;
        if (this._showGettingStarted) {
            localStorage.setItem(`showGettingStarted_${this.organization?.name}`, 'true');
        } else {
            localStorage.setItem(`showGettingStarted_${this.organization?.name}`, 'false');
        }
        this.identityPreferencesService.setPreference('showGettingStarted', this._showGettingStarted);
    }

    _enableDeleteProtection;

    public get enableDeleteProtection() {
        if (!this.user) {
            return false;
        }
        const tenantId = localStorage.getItem('tenantId');
        const localStorageSetting = localStorage.getItem('enableDeleteProtection_' + tenantId) === 'on';
        if (this.orgPreferences?.enableDeleteProtection === false) {
            return false;
        } else {
            return localStorageSetting || this.orgPreferences?.enableDeleteProtection;
        }
    }

    public get darkModeEnabled() {
        const localSetting = localStorage.getItem('darkMode_' + this.tenant?.label);
        const identityPrefSetting = this.identityPreferencesService.getPreference('darkModeEnabled');
        if (_.isEmpty(localSetting) && _.isNil(identityPrefSetting)) {
          return this.environment.defaultTheme === 'dark';
        }

        const isEnabledLocal = localSetting === 'true';
        if (!this.tenant) {
            if (!_.isNil(identityPrefSetting)) {
              return identityPrefSetting;
            } else {
              return isEnabledLocal;
            }
        }

        if (isEnabledLocal) {
            return true;
        } else if (identityPrefSetting === 'true' || identityPrefSetting === true) {
            return true;
        } else if (this.isCloudZiti && identityPrefSetting === 'auto') {
            const systemDarkmodeEnabled = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            return systemDarkmodeEnabled;
        }
        return false;
    }

    public set darkModeEnabled(enabled: any) {
        localStorage.setItem('darkMode_' + this.tenant?.label, enabled.toString());
        if (!this.tenant) {
            return;
        }
        this.identityPreferencesService.setPreference('darkModeEnabled', enabled);
    }

    public isNewSelfServiceWorkflow(tenantLabel?) {
        tenantLabel = tenantLabel || this.organization?.name;
        const newSelfService = localStorage.getItem(`nf_new_self_service_${tenantLabel}`) === 'true';
        const showGettingStarted = localStorage.getItem(`showGettingStarted_${tenantLabel}`) === 'true';
        const showSurvey = localStorage.getItem(`showSurvey_${tenantLabel}`) === 'true';
        const isTeams = localStorage.getItem(`isSelfServiceTeams_${tenantLabel}`) === 'true';
        return newSelfService && showGettingStarted && showSurvey && isTeams;
    }

    public get freemiumBillingEnabled() {
        if (!this.user) {
            return false;
        }
        return localStorage.getItem('freemiumBillingEnabled' + this.user.id) === 'on' || true;
    }

    // Check for the feature toggle
    public checkFeatures(orgId) {
        this.logger.info('Check features');

        if (this.environment.production && this.environment.domain !== 'staging') {
            this.experimentalFeatures = false;
            this.experimentalFeaturesChanged.emit(this.experimentalFeatures);
            this.logger.info('Prod environment, disable experimental features');
            return;
        }

        if (orgId === null) {
            this.logger.info('No organization set, skip feature check');
            return;
        }

        // check for experimental features
        if (localStorage.getItem('experimentalFeatures_' + orgId) === 'on') {
            this.logger.info('Experimental features are enabled');
            this.experimentalFeatures = true;
        } else {
            this.logger.info('Experimental features are disabled');
            this.experimentalFeatures = false;
        }
        this.experimentalFeaturesChanged.emit(this.experimentalFeatures);
    }

    // Don't even give the option for hidden features in prod environments
    public allowHiddenFeatures() {
        return this.environment.production === false || this.environment.domain === 'staging';
    }

    public setExperimentalFeatures(flag) {
        if (this.allowHiddenFeatures() === false) {
            this.experimentalFeatures = false;
            this.experimentalFeaturesChanged.emit(this.experimentalFeatures);
            return;
        }

        const orgId = localStorage.getItem('organizationId');
        if (flag) {
            localStorage.setItem('experimentalFeatures_' + orgId, 'on');
            this.experimentalFeatures = true;
        } else {
            localStorage.setItem('experimentalFeatures_' + orgId, 'off');
            this.experimentalFeatures = false;
        }
        this.experimentalFeaturesChanged.emit(this.experimentalFeatures);
    }

    public setEnableDeleteProtection(flag) {
        if (flag) {
            localStorage.setItem('enableDeleteProtection_' + this.tenant.id, 'on');
            this._enableDeleteProtection = true;
        } else {
            localStorage.setItem('enableDeleteProtection_' + this.tenant.id, 'off');
            this._enableDeleteProtection = false;
        }
        return this.organizationPreferencesService.setOrganizationPreference(
            'enableDeleteProtection',
            this._enableDeleteProtection
        );
    }

    _loadingIndicatorFile = '/assets/svgs/neutral-loading-indicator.svg';
    public setLoadingIndicatorFile(indicatorPath) {
      this._loadingIndicatorFile = indicatorPath;
      return this.organizationPreferencesService.setOrganizationPreference(
        'loadingIndicatorFile',
        this._loadingIndicatorFile
      );
    }

    public get loadingIndicatorFile() {
      if (!this.user) {
        return this._loadingIndicatorFile;
      }

      if (this.orgPreferences?.loadingIndicatorFile) {
        return this.orgPreferences?.loadingIndicatorFile;
      } else {
        return this._loadingIndicatorFile;
      }
    }

    public setPowerUserEnabled(flag) {
        if (flag) {
            localStorage.setItem('powerUserEnabled_' + this.user.id, 'on');
            this._powerUserEnabled = true;
        } else {
            localStorage.setItem('powerUserEnabled_' + this.user.id, 'off');
            this._powerUserEnabled = false;
        }
        return this.identityPreferencesService.setPreference('powerUserEnabled', this._powerUserEnabled);
    }

    public whiteLabelSupported(network) {
        const majorVersionNumber = this.apiService.getNetworkVersion(network);
        const minorVersionNumber = this.apiService.getNetworkMinorVersion(network);
        const patchVersionNumber = this.apiService.getNetworkPatchVersion(network);
        if (
            majorVersionNumber < 7 ||
            (majorVersionNumber === 7 && minorVersionNumber < 3) ||
            (majorVersionNumber === 7 && minorVersionNumber === 3 && patchVersionNumber < 86)
        ) {
            return false;
        } else {
            return true;
        }
    }

    public getShowInfoBubble(type) {
        const localStorageSetting = localStorage.getItem(`show_${type}_info_${this.organization?.name}`) === 'true';
        return localStorageSetting || this.userPreferences?.showGettingStarted;
    }

    public setShowInfoBubble(type, show) {
        if (show) {
            localStorage.setItem(`show_${type}_info_${this.organization?.name}`, 'true');
        } else {
            localStorage.setItem(`show_${type}_info_${this.organization?.name}`, 'false');
        }
    }

    async getCurrentUser() {
        await this.get('identities', 'self')
            .toPromise()
            .then((identity) => {
                this.user = identity;
                this.experimentalFeaturesChanged.emit(this.experimentalFeatures);
            });
    }

    public isSelfServiceTeamsTier(subscription) {
        const cloudRegions = _.get(subscription, 'subscriptionItems.cloudRegionsQuantity', 0);
        const cloudProviders = _.get(subscription, 'subscriptionItems.cloudProvidersQuantity', 0);
        return cloudRegions === '1' && cloudProviders === '1';
    }

    public isSelfServiceGrowthTier(subscription) {
        const cloudRegions = _.get(subscription, 'subscriptionItems.cloudRegionsQuantity', 0);
        const cloudProviders = _.get(subscription, 'subscriptionItems.cloudProvidersQuantity', 0);
        return cloudRegions === '2' && cloudProviders === '2';
    }

    public isSelfServicePAYG(subscription) {
        const paygSupport = _.get(subscription, 'subscriptionItems.freemiumPAYGSupport', '');
        return paygSupport === 'true' || paygSupport === true;
    }

    public isSelfServiceAccount(subscription) {
        const isFreemiumPAYG = this.isSelfServicePAYG(subscription);
        const isSelfServiceTeams = this.isSelfServiceTeamsTier(subscription);
        const isSelfServiceGrowth = this.isSelfServiceGrowthTier(subscription);
        return isFreemiumPAYG || isSelfServiceTeams || isSelfServiceGrowth;
    }

    public isCancelledAccount(subscription) {
        if (_.isEmpty(subscription?.cancelledAt)) {
            return false;
        }
        return moment(subscription.cancelledAt).isBefore(Date.now());
    }

    isBrowzerSupportedNetwork(network) {
        return semver.gte(network.productVersion, this.minBrowzerVersion);
    }

    public isCloudZitiNetwork(network) {
        if (_.isEmpty(network?.productVersion)) {
            return false;
        }
        const networkVersion = this.networkVersionService.getNetworkVersion(network);
        const minorVersion = this.networkVersionService.getNetworkMinorVersion(network);
        const patchVersion = this.networkVersionService.getNetworkPatchVersion(network);
        return (
            (networkVersion >= this.minCloudZitiVersion &&
                minorVersion >= this.minCloudZitiMinorVersion &&
                patchVersion >= this.minCloudZitiPatchVersion) ||
            network.name === 'v8manualtest'
        );
    }

    public canDisabledServiceEncryption(network) {
        const networkVersion = this.networkVersionService.getNetworkVersion(network);
        const minorVersion = this.networkVersionService.getNetworkMinorVersion(network);
        const patchVersion = this.networkVersionService.getNetworkPatchVersion(network);
        if (
            networkVersion > 7 ||
            (networkVersion >= 7 && minorVersion > 3) ||
            (networkVersion >= 7 && minorVersion >= 3 && patchVersion >= 91)
        ) {
            return true;
        } else {
            return false;
        }
    }
    /**
     * Get item by ID
     * Allowed Types:
     *    identity-permission
     *    user-identities
     *    invitations
     *    tenants
     *    operations
     */
    public get(type: string, id: string) {
        const fullpath = `${this.apiUrl}${type}/${id}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: any, error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            scope.logger.error('Feature Service error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            scope.logger.error(`Feature Backend returned code ${error.status}`, error.error);
        }
        scope.lastErrorSource.next(error);
        return observableThrowError(error);
    }

    /**
     * Return headers
     */
    private setHeaders(): HttpHeaders {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (tokenResponse.expired) {
            // if the token is expired, return null
            headers = null;
        } else if (tokenResponse.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        if (headers != null) {
            return headers.append('Content-Type', 'application/json');
        } else {
            return headers;
        }
    }
}
