import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FeatureService, LoggerService } from '@netfoundry-ui/shared/services';
// Import the resized event model
import $ from 'jquery';
import _ from 'lodash';
import { TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { AgGridAngular } from 'ag-grid-angular';
import moment from 'moment';
import { TableCellActiveComponent } from './table-cell-active/table-cell-active.component';
import { TableCellDetailsComponent } from './table-cell-details/table-cell-details.component';
import { TableCellEmailComponent } from './table-cell-email/table-cell-email.component';
import { TableCellEventsComponent } from './table-cell-events/table-cell-events.component';
import { TableCellMenuComponent } from './table-cell-menu/table-cell-menu.component';
import { TableCellMFAComponent } from './table-cell-mfa/table-cell-mfa.component';
import { TableCellOSComponent } from './table-cell-os/table-cell-os.component';
import { TableCellProcessInfoComponent } from './table-cell-process-info/table-cell-process-info.component';
import { TableCellRegistrationComponent } from './table-cell-registration/table-cell-registration.component';
import { TableCellResourceStatusComponent } from './table-cell-resource-status/table-cell-resource-status.component';
import { TableCellSelectComponent } from './table-cell-select/table-cell-select.component';
import { TableCellManagedComponent } from './table-cell-managed/table-cell-managed.component';
import { TableCellSessionComponent } from './table-cell-session/table-cell-session.component';
import { TableCellUpgradableComponent } from './table-cell-upgradable/table-cell-upgradable.component';
import { TableHeaderDefaultComponent } from './table-header-default/table-header-default.component';
import { TableHeaderMenuComponent } from './table-header-menu/table-header-menu.component';
import { TableHeaderProcessComponent } from './table-header-process/table-header-process.component';
import { TableHeaderSelectComponent } from './table-header-select/table-header-select.component';

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnChanges, OnInit {
    @Input() rowData;
    @Input() expandRowData;
    @Input() collapseRowData;
    @Input() rowValidation;
    @Input() openItem;
    @Input() goToEdit;
    @Input() openTopology;
    @Input() openMetrics;
    @Input() openJson;
    @Input() openPublicCertModal;
    @Input() openEvents;
    @Input() editItem;
    @Input() editItemDetail;
    @Input() deleteItem;
    @Input() exportItem;
    @Input() shareItem;
    @Input() toggleAll;
    @Input() toggleItem;
    @Input() downloadAll;
    @Input() downloadAllEvents;
    @Input() downloadFilteredEvents;
    @Input() downloadCsv;
    @Input() columnFilters;
    @Input() isTimeSearchAvailable: boolean;
    @Input() isNotDialLogs: boolean;
    @Input() isUTC: boolean;
    @Input() newStartTime;
    @Input() newEndTime;
    @Input() filterChanged;
    @Input() isLoading;
    @Input() resetMFA;
    @Input() move;
    @Input() releaseIP;
    @Input() activateItem;
    @Input() deactivateItem;
    @Input() resetItem;
    @Input() reissueToken;
    @Input() restoreBackup;
    @Input() enableHA;
    @Input() createBackup;
    @Input() restartAll;
    @Input() reEnroll;
    @Input() tableId = '';
    @Input() validateService;
    @Input() rowsToggled;
    @Input() tableRefreshCount;
    @Input() serverSideDataService;
    @Input() showToggle;
    @Input() upgrade;
    @Input() resume;
    @Input() suspend;
    @Input() resize;
    @Input() upgradeBootstrapper;
    @Input() restartHost;
    @Input() restart;
    @Input() restartProcess;
    @Input() pagination = false;
    @Input() paginationPageSize = 30;
    @Input() hiddenResults = false;
    @Input() upgradeEdgeRouters;
    @Input() updateAuthPolicy;
    @Input() hideSelectColumn;

    @Output() gridReady = new EventEmitter();
    @Output() dragStart = new EventEmitter();
    @Output() dragLeave = new EventEmitter();
    @Output() pageChanged = new EventEmitter();
    @Output() systemToggleChanged = new EventEmitter();
    @Output() refresh = new EventEmitter();

    selectedRange = '';
    dateValue = undefined;
    loadingHiddenResults = false;
    frameworkComponents;
    gridModules;
    gridOptions;
    visibleColumns;
    hiddenColumns;
    visibleColumnIds;
    hiddenColumnIds;
    mergedColumnDefinitions;
    openMenu;
    openHeaderMenu;
    menuLeft;
    menuTop;
    gridRendered;
    resizeGridColumnsDebounced;
    allToggled;
    selectedItem = {
        actionList: [],
    };
    dateTimeColumn = '';
    showDateTimePicker = false;
    showFilterOptions = false;
    filterOptions = [];
    appliedFilters = [];

    subTreeDataService;
    isServerSideGroup;
    getServerSideGroupKey;
    autoGroupColumnDef;
    getDataPath;
    showSystem = false;
    _view = 'list';

    _rowData;
    public menuColumnDefinition = {
        colId: 'nf-ag-menu',
        field: '',
        resizable: false,
        width: this.remToPx(3.125),
        sortable: false,
        lockPosition: true,
        suppressMovable: true,
        headerClass: 'menuHeader',
        cellClass: 'tCol cellMenu',
        cellRenderer: 'cellMenuComponent',
        cellRendererParams: {
            openMenu: ($event, item) => {
                this.openActionMenu($event, item);
            },
            closeMenu: (event) => {
                this.closeActionMenu();
            },
        },
        suppressSizeToFit: true,
        pinned: 'right',
        headerComponent: TableHeaderMenuComponent,
        headerComponentParams: {
            openHeaderMenu: ($event) => {
                this.openHeaderActionMenu($event);
            },
            closeMenu: (event) => {
                this.closeActionMenu();
            },
        },
    };
    private _initialColumnDefs;
    private _gridObj;
    private _refreshCellsDebounced;
    private _onColumnsResizedDebounced;
    private _saveColumnStateDebounced;

    private selectColumnDefinition = {
        colId: 'nf-ag-selected',
        field: '',
        suppressSizeToFit: true,
        lockPosition: true,
        suppressMovable: true,
        resizable: false,
        pinned: 'left',
        width: 60,
        sortable: false,
        headerClass: 'selectHeader',
        cellClass: 'tCol',
        cellRenderer: 'cellSelectComponent',
        cellRendererParams: {
            toggleItem: (item) => {
                if (!this.toggleItem) {
                    return;
                }
                this.toggleItem(item);
                this.rowData.forEach((row) => {
                    if (item.id === row.id) {
                        row.selected = item.selected;
                    }
                });
                this._gridObj.api.nfAllToggled = _.every(this.rowData, { selected: true });
            },
        },
        headerComponent: TableHeaderSelectComponent,
        headerComponentParams: {
            toggleAll: (selected: boolean) => {
                if (!this.toggleAll || !this._gridObj) {
                    return;
                }
                _.forEach(this.rowData, (row) => {
                    row.selected = selected;
                });
                this.toggleAll();
                _.defer(() => {
                    this._gridObj.api.refreshCells({ force: true });
                });
            },
        },
    };

    @ViewChild('calendar', { static: false }) calendar: any;
    @ViewChild('contextMenu') contextMenu;

    constructor(
        public featureService: FeatureService,
        private logger: LoggerService,
        private filterService: TableFilterService
    ) {}

    private _columnDefinitions;

    get columnDefinitions(): any {
        return this._columnDefinitions;
    }

    @Input() set columnDefinitions(value: any) {
        this._columnDefinitions = value;
    }

    @Input() set view(value: any) {
        this._view = value;
    }

    get showFilter(): boolean {
        return this._view !== 'upload' && this._view !== 'process';
    }

    @Input() set refreshCount(value: number) {
        if (!this._gridObj) {
            return;
        }
        _.defer(() => {
            this._gridObj.api.redrawRows();
            this._gridObj.api.refreshCells({ force: true });
        });
    }

    enablePowerUser() {
        if (this.loadingHiddenResults) {
            return;
        }
        this.loadingHiddenResults = true;
        this.featureService
            .setPowerUserEnabled(true)
            .then(() => {
                this.loadingHiddenResults = false;
                this.refresh.emit();
            })
            .catch(() => {
                this.loadingHiddenResults = false;
                this.refresh.emit();
            });
    }

    ngOnInit() {
        this.frameworkComponents = {
            cellSelectComponent: TableCellSelectComponent,
            cellMenuComponent: TableCellMenuComponent,
            cellRegistrationComponent: TableCellRegistrationComponent,
            cellResourceStatusComponent: TableCellResourceStatusComponent,
            cellSessionComponent: TableCellSessionComponent,
            cellOSComponent: TableCellOSComponent,
            cellManagedComponent: TableCellManagedComponent,
            headerCellSelectComponent: TableHeaderSelectComponent,
            headerHeaderMenuComponent: TableHeaderMenuComponent,
            headerDefaultComponent: TableHeaderDefaultComponent,
            headerProcessComponent: TableHeaderProcessComponent,
            cellMFAComponent: TableCellMFAComponent,
            cellDetailsComponent: TableCellDetailsComponent,
            cellProcessInfoComponent: TableCellProcessInfoComponent,
            cellEmailComponent: TableCellEmailComponent,
            cellActiveComponent: TableCellActiveComponent,
            versionUpgradeableComponent: TableCellUpgradableComponent,
            cellEventsComponent: TableCellEventsComponent,
        };
        this.resizeGridColumnsDebounced = _.debounce(this.resizeGridColumns.bind(this), 20, { leading: true });
        this._refreshCellsDebounced = _.debounce(this._refreshCells.bind(this), 50);
        this._onColumnsResizedDebounced = _.debounce(this._onColumnsResized.bind(this), 400);
        this._saveColumnStateDebounced = _.debounce(this._saveColumnState.bind(this), 400);
        this.isServerSideGroup = function (dataItem) {
            const isGroup = _.get(dataItem, 'processTree', []).length === 1;
            return isGroup;
        };
        this.getServerSideGroupKey = function (dataItem) {
            return dataItem.processId;
        };

        this.autoGroupColumnDef = {
            field: 'processTree',
            headerName: 'Process Name',
            cellRenderer: 'cellResourceStatusComponent',
            resizable: true,
            cellRendererParams: { resourceType: 'process-execution' },
            headerComponent: TableHeaderDefaultComponent,
        };
        this.getDataPath = function (data) {
            return data.processTree;
        };

        if (this._columnDefinitions) {
            this._addColumnEvents();
            this._addDefaultColumnDefs();
            this._storeInitialColumnDefs();
            this._setColumnWidths();
            this._setColumnOrderAndVisibility();
            this._initColumnVisibility();
            this._initGridOptions();
        }
    }

    ngOnChanges(changes: any): void {
        if (this._gridObj && changes.rowData) {
            this._refreshCellsDebounced(changes.rowData);
        }
    }

    setColumnVisibilityColumn(column, visible) {
        this._gridObj.api.setColumnsVisible([column.colId], visible);
        this._gridObj.api.refreshHeader();
        _.defer(() => {
            this._updateColumnVisibility(column.colId, visible);
        });
    }

    onCellClicked(event) {
        this.checkSelectedText(event);
    }

    checkSelectedText(event) {
        if (!window.getSelection) {
            return false;
        }
        const selectedText = window.getSelection()?.toString();
        const targetValue = event?.value || '';
        event.nfTextSelected = !_.isEmpty(selectedText) && targetValue.includes(selectedText);
    }

    openActionMenu(event, item): void {
        this.selectedItem = item;
        this.openMenu = true;
        _.delay(() => {
            const height = this.contextMenu?.nativeElement?.offsetHeight || 120;
            const windowOffset = window.innerHeight - event.clientY;
            const menuOffset = windowOffset <= height ? height - windowOffset : 0;
            this.menuLeft = event.clientX - 100;
            this.menuTop = event.clientY + 5 - menuOffset;
        }, 10);
    }

    onGridReady(params) {
        this.gridReady.emit(params);
    }

    showSystemToggled() {
        this.showSystem = !this.showSystem;
        this.systemToggleChanged.emit(this.showSystem);
    }

    resetTableColumns() {
        if (!this._gridObj) {
            return;
        }
        this.mergedColumnDefinitions = _.cloneDeep(this._initialColumnDefs);
        this._gridObj.api.setColumnDefs(this.mergedColumnDefinitions);
        this._resetCookieConfig();
        this._gridObj.api.resetColumnState();
        _.defer(() => {
            this._updateHiddenColumns();
            this.resizeGridColumns();
        });
    }

    applyFilter(event, filter) {
        const filterExists = _.some(this.appliedFilters, { columnId: filter.columnId });
        if (filterExists) {
            if (_.isEmpty(_.toString(filter.value))) {
                _.remove(this.appliedFilters, { columnId: filter.columnId });
            } else {
                this.appliedFilters = _.map(this.appliedFilters, (appliedFilter) => {
                    if (appliedFilter.columnId === filter.columnId) {
                        appliedFilter = filter;
                    }
                    return appliedFilter;
                });
            }
        } else if (!_.isEmpty(_.toString(filter.value))) {
            this.appliedFilters.push(filter);
        }
        if (filter.exclude) {
          this.appliedFilters = _.filter(this.appliedFilters, (appliedFilter) => {
            return appliedFilter.columnId !== filter.exclude;
          });
          _.set(this.columnFilters, filter.exclude, '');
        }
        if (filter.useTextInput) {
            this.filterService.showTextInput.emit(filter);
            return;
        }
        _.set(this.columnFilters, filter.columnId, filter.value);
        this.filterChanged(filter.columnId, filter.value);
        this.closeHeaderFilter(event);
        _.defer(() => {
            this.filterService.externalFiltersChanged.emit(this.filterService.externalFilters);
        });
    }

    removeFilter(event) {
        this.filterChanged(event.columnId, undefined);
        _.remove(this.appliedFilters, (filter) => filter.columnId === event.columnId);
    }

    openHeaderActionMenu(event): void {
        this.openHeaderMenu = true;
        this.menuLeft = event.clientX - 100;
        this.menuTop = event.clientY + 5;
    }

    openHeaderFilter(event, options, type, columnId): void {
        this.filterOptions = options;
        this.menuLeft = event.clientX;
        this.menuTop = event.clientY + 10;
        if (type === 'DATETIME') {
            this.showDateTimePicker = true;
            this.dateTimeColumn = columnId;
            _.delay(() => {
                this.calendar.toggle();
            }, 100);
        } else {
            _.defer(() => {
                this.showFilterOptions = true;
            });
        }
    }

    closeActionMenu(): void {
        this.selectedItem = {
            actionList: [],
        };
        this.openMenu = false;
        this.openHeaderMenu = false;
    }

    closeHeaderFilter(event): void {
        this.showFilterOptions = false;
    }

    resizeGridColumns(event = {}) {
        if (!this._gridObj) {
            return;
        }
        _.defer(() => {
            this._gridObj.api.sizeColumnsToFit();
        });
    }

    sortChanged(event) {
        this.logger.info(event);
        event = undefined;
        return false;
    }

    anySelected() {
        return _.some(this.rowData, { selected: true });
    }

    showHeader() {
        return this._view !== 'attributes';
    }

    showDownload() {
        return this.downloadAll && this._view !== 'process';
    }
    showDownloadEvents() {
        return this.downloadAllEvents && this._view !== 'process';
    }
    showBulkUpload() {
        return this.upgradeEdgeRouters && this.tableId === 'edge-routers';
    }
    
    showRestartAll() {
        return this.restartAll && this.tableId === 'network-controllers'
    }

    getRowNodeId(row) {
        return row?.data?.id ? row?.data?.id : row?.data?.name ? row?.data?.name : 'new_row_' + row?.data?.itemIndex;
    }

    _refreshCells(rowData) {
        if (!this._gridObj) {
            return;
        }
        const dataChanged = !_.isEqual(rowData.previousValue, rowData.currentValue);
        if (dataChanged) {
            this._gridObj.api.nfAllToggled = !_.isEmpty(this.rowData) && _.every(this.rowData, { selected: true });
            this._gridObj.api.refreshCells({ force: true });
        }
    }

    _initGridOptions() {
        this.gridOptions = {
            pagination: this.pagination,
            paginationPageSize: this.paginationPageSize,
            rowSelection: 'single',
            rowClass: 'tRow',
            rowHeight: this.remToPx(3.125),
            suppressPaginationPanel: true,
            headerHeight: this.remToPx(3.125),
            deltaRowDataMode: true,
            accentedSort: true,
            suppressRowClickSelection: true,
            suppressHorizontalScroll: false,
            stopEditingWhenGridLosesFocus: true,
            suppressPropertyNamesCheck: true,
            animateRows: true,
            defaultColDef: {
                sortable: true,
                filter: true, // set filtering on for all columns
            },
            onRowDragMove: (params) => {
                this.dragStart.emit(params);
            },
            onRowDragLeave: (params) => {
                this.dragLeave.emit(params);
            },
            onRowDragEnd: () => {
                $('.attribute-item').show();
                $('.drag-hover').removeClass('drag-hover');
                $('#ColumnVisibilityHeader').trigger('click');
                $('.new-attribute-target').hide();
            },
            rowClassRules: {
                // row style function
                'row-invalid': function (params) {
                    return _.get(params, 'data.invalid');
                },
                'row-copied': function (params) {
                    return _.get(params, 'data.copied');
                },
                'row-child-sub-process': function (params) {
                    return (
                        params.api.view === 'process' &&
                        !_.get(params, 'data.isRoot') &&
                        !_.isEmpty(_.get(params, 'data.subprocessId'))
                    );
                },
                'row-child': function (params) {
                    return (
                        params.api.view === 'process' &&
                        !_.get(params, 'data.isRoot') &&
                        !_.isEmpty(_.get(params, 'data.parentId'))
                    );
                },
                'row-child-second': function (params) {
                    return (
                        params.api.view === 'process' &&
                        !_.get(params, 'data.isRoot') &&
                        _.get(params, 'data.isParallel')
                    );
                },
                'row-disabled': function (params) {
                    return _.get(params, 'data.enabled') === false;
                },
                'row-child-even': function (params) {
                    return params.api.view === 'process' && _.get(params, 'data.parentIndex') % 2 > 0;
                },
                'row-child-odd': function (params) {
                    return params.api.view === 'process' && _.get(params, 'data.parentIndex') % 2 === 0;
                },
                'row-child-warn': function (params) {
                    return params.api.view === 'process' && _.get(params, 'data.state') === 'WARNING';
                },
                'row-child-header': function (params) {
                    return params.api.view === 'process' && _.get(params, 'data.rowType') === 'step-header';
                },
                'row-role-item': function (params) {
                    return _.get(params, 'data.roleItem');
                },
            },
            getRowHeight: (params) => {
                if (params.api.view === 'process') {
                    if (_.get(params, 'data.isRoot')) {
                        return this.remToPx(3.125);
                    }
                    if (_.get(params, 'data.isParallel')) {
                        return this.remToPx(2.1875);
                    }
                    if (_.get(params, 'data.parentId')) {
                        return this.remToPx(2.5);
                    }
                    return this.remToPx(3.125);
                } else if (params.api.view === 'users') {
                    if (_.get(params, 'data.roleItem')) {
                        return this.remToPx(2.1875);
                    }
                    return this.remToPx(3.125);
                } else {
                    return this.remToPx(3.125);
                }
            },

            onCellEditingStopped: (eventObj) => {
                if (!this.validateService) {
                    return;
                }
                const field = _.get(eventObj, 'colDef.field', '');
                if (_.includes(field.toLowerCase(), 'attribute')) {
                    const newVal = eventObj.newValue.split(',');
                    _.set(eventObj, `data.${field}`, newVal);
                } else {
                    _.set(eventObj, `data.${field}`, eventObj.newValue);
                }
                this._validateTable(eventObj);
            },
            getRowNodeId: (data) => data.name,
            onBodyScroll: (scroller) => {
                this._handleTableScroll(scroller);
            },
            onGridReady: (grid) => {
                grid.api.nfAllToggled = false;
                grid.api.nfHideColumn = this.setColumnVisibilityColumn.bind(this);
                grid.api.nfApplyFilter = this.applyFilter.bind(this);
                grid.api.columnFilters = this.columnFilters;
                grid.api.openHeaderFilter = this.openHeaderFilter.bind(this);
                grid.api.closeHeaderFilter = this.closeHeaderFilter.bind(this);
                grid.api.validateTable = this._validateTable.bind(this);
                grid.api.view = this._view;
                grid.api.nfRowData = this.rowData;
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                grid.api.rowsToggled = this.rowsToggled ? this.rowsToggled.bind(this) : () => {};
                if (this.serverSideDataService) {
                    grid.api.setServerSideDatasource(this.serverSideDataService);
                }
                this._gridObj = grid;
                _.defer(this._applyColumnState.bind(this));
            },
            onColumnVisible: (eventObj) => {
                if (!eventObj.column) {
                    return;
                }
                this._updateColumnVisibility(eventObj.column.colDef.colId, eventObj.visible);
                if (eventObj.visible) {
                    this._gridObj.api.moveColumns(
                        [eventObj.column.colDef.colId],
                        this.mergedColumnDefinitions.length - 1
                    );
                    _.defer(() => {
                        this._gridObj.api.ensureColumnVisible(eventObj.column.colDef.colId);
                    });
                }
                this._updateGridColumns();
                this._saveColumnState();
            },
            onFirstDataRendered: (eventObj) => {
                this.resizeGridColumns();
                this.gridRendered = true;
            },
            onColumnPinned: (eventObj) => {
                if (!eventObj.column) {
                    return;
                }
                const colId = eventObj.column.colDef.colId;
                const pinned = eventObj.pinned === 'left';
                this.mergedColumnDefinitions = _.map(this.mergedColumnDefinitions, (colDef) => {
                    if (colDef.colId === colId) {
                        colDef.lockPosition = pinned;
                        colDef.suppressMovable = pinned;
                    }
                    return colDef;
                });
                this._updateGridColumns();
                this.resizeGridColumns();
                this._saveColumnState();
            },
            onColumnResized: (eventObj) => {
                if (!eventObj.column || eventObj.source === 'sizeColumnsToFit') {
                    return;
                }
                this._onColumnsResizedDebounced(eventObj.column);
            },
            onColumnMoved: (eventObj) => {
                this._saveColumnStateDebounced();
            },
        };
    }

    _validateTable(eventObj) {
        this.rowData[eventObj.rowIndex] = eventObj.data;
        _.delay(() => {
            this.validateService(this.rowData);
            _.delay(() => {
                this._gridObj.api.refreshCells({ force: true });
                this._gridObj.api.redrawRows();
            }, 50);
        }, 50);
    }

    _onColumnsResized(column) {
        this._saveColumnWidths(column);
    }

    _resetCookieConfig() {
        localStorage.removeItem(`nf_${this.tableId}_table_state`);
        localStorage.removeItem(`nf_${this.tableId}_column_widths`);
    }

    _saveColumnState() {
        const tableState = this._gridObj.api.getColumnState();
        const tableStateCookie = JSON.stringify(tableState);
        localStorage.setItem(`nf_${this.tableId}_table_state`, tableStateCookie);
    }

    _applyColumnState() {
        const tableStateCookie = localStorage.getItem(`nf_${this.tableId}_table_state`);
        const tableState = JSON.parse(tableStateCookie);
        if (tableState) {
            this._gridObj.api.applyColumnState({ state: tableState });
            this._updateHiddenColumns();
        }
    }

    _saveColumnWidths(column) {
        if (!column) {
            return;
        }
        const columnId = column.colId;
        const columnWidth = column.actualWidth;
        let colWidthsCookie = localStorage.getItem(`nf_${this.tableId}_column_widths`);
        let colWidths: any = {};
        if (!_.isEmpty(colWidthsCookie)) {
            colWidths = JSON.parse(colWidthsCookie);
        }
        colWidths[columnId] = columnWidth;
        colWidthsCookie = JSON.stringify(colWidths);
        localStorage.setItem(`nf_${this.tableId}_column_widths`, colWidthsCookie);

        let tableStateCookie = localStorage.getItem(`nf_${this.tableId}_table_state`);
        let tableState;
        if (_.isEmpty(tableStateCookie)) {
            tableState = this._gridObj.api.getColumnState();
        } else {
            tableState = JSON.parse(tableStateCookie);
        }
        _.forEach(tableState, (column) => {
            if (columnId === column.colId) {
                column.suppressSizeToFit = true;
                column.width = columnWidth;
            }
        });
        tableStateCookie = JSON.stringify(tableState);
        localStorage.setItem(`nf_${this.tableId}_table_state`, tableStateCookie);
        _.forEach(this._gridObj.api?.columnModel?.columnDefs, (colDef) => {
            if (colDef.colId === columnId) {
                colDef.suppressSizeToFit = true;
            }
        });
    }

    _addColumnEvents() {
        this._columnDefinitions = _.map(this._columnDefinitions, (colDef) => {
            colDef.pinColumn = this._pinColumn.bind(this);
            colDef.cellClass = this._getCellStyle.bind(this);
            return colDef;
        });
    }

    _getCellStyle(params) {
        if (!this.rowValidation || this.rowValidation.length <= 0) {
            return '';
        }
        const validationResult = this.rowValidation[params.node.rowIndex];
        if (!validationResult || !validationResult.errors) {
            return '';
        }
        const field = _.get(params, 'colDef.field', '');
        if (validationResult.errors[field]) {
            return 'nf-table-cell-error';
        }
    }

    _pinColumn(column, pinned) {
        this._gridObj.api.setColumnPinned(column, pinned);
    }

    _updateColumnVisibility(colId, visible) {
        this._columnDefinitions = _.map(this._columnDefinitions, (colDef) => {
            if (colDef.colId === colId) {
                colDef.hide = !visible;
            }
            return colDef;
        });
        for (const colDef of this.mergedColumnDefinitions) {
            if (colDef.colId === colId) {
                colDef.hide = !visible;
            }
        }
        this._updateHiddenColumns();
        this.resizeGridColumns();
    }

    _updateHiddenColumns() {
        const gridColumnDefs = this._gridObj.api?.columnModel?.gridColumns;
        const visibleGridColumns = gridColumnDefs.filter((col) => col.visible);
        const hiddenGridColumns = gridColumnDefs.filter((col) => !col.visible);
        this.visibleColumns = _.map(visibleGridColumns, 'colDef');
        this.visibleColumnIds = _.map(this.visibleColumns, 'field');
        this.hiddenColumns = _.map(hiddenGridColumns, 'colDef');
        this.hiddenColumnIds = _.map(this.hiddenColumns, 'field');
    }

    _addDefaultColumnDefs() {
        this.mergedColumnDefinitions = _.cloneDeep(this._columnDefinitions);
        if (this._view === 'process') {
            const cellParams = _.cloneDeep(this.menuColumnDefinition.cellRendererParams);
            const mergedParams = _.merge(
                this.mergedColumnDefinitions[this.mergedColumnDefinitions.length - 1].cellRendererParams,
                cellParams
            );
            this.mergedColumnDefinitions[this.mergedColumnDefinitions.length - 1].cellRendererParams = mergedParams;
            this.mergedColumnDefinitions[this.mergedColumnDefinitions.length - 1].headerComponentParams =
                this.menuColumnDefinition.headerComponentParams;
        } else if (this.hideSelectColumn) {
            this.mergedColumnDefinitions.push(this.menuColumnDefinition);
        } else if (this._view !== 'upload' && this._view !== 'attributes') {
            this.mergedColumnDefinitions.splice(0, 0, this.selectColumnDefinition);
            this.mergedColumnDefinitions.push(this.menuColumnDefinition);
        }
    }

    _storeInitialColumnDefs() {
        this._initialColumnDefs = _.cloneDeep(this.mergedColumnDefinitions);
    }

    _initColumnVisibility() {
        this.visibleColumns = this._columnDefinitions.filter((col) => !col.hide);
        this.hiddenColumns = this._columnDefinitions.filter((col) => col.hide);
        this.visibleColumnIds = _.map(this.visibleColumns, 'field');
    }

    _updateGridColumns() {
        // this._addDefaultColumnDefs();
        this._setColumnWidths();
        this._gridObj.api.setColumnDefs(this.mergedColumnDefinitions);
        this._gridObj.api.refreshHeader();
    }

    _setColumnWidths() {
        if (this._view === 'upload' || this._view === 'process') {
            return;
        }
        const colWidthsCookie = localStorage.getItem(`nf_${this.tableId}_column_widths`);
        if (!_.isEmpty(colWidthsCookie)) {
            const colWidths = JSON.parse(colWidthsCookie);
            _.forEach(colWidths, (colWidth, colId) => {
                _.forEach(this.mergedColumnDefinitions, (colDef) => {
                    if (colDef.colId === colId) {
                        colDef.width = colWidth;
                        colDef.suppressSizeToFit = true;
                    }
                });
            });
        }
    }

    _setColumnOrderAndVisibility() {
        if (this._view === 'upload' || this._view === 'process') {
            return;
        }
        try {
            const tableStateCookie = localStorage.getItem(`nf_${this.tableId}_table_state`);
            if (!_.isEmpty(tableStateCookie) || typeof tableStateCookie === 'undefined') {
                const colStates = JSON.parse(tableStateCookie);
                const columnIndexes = [];
                const index = 0;
                _.forEach(colStates, (colState, index) => {
                    _.forEach(this.mergedColumnDefinitions, (colDef) => {
                        if (colDef.colId === colState.colId) {
                            columnIndexes.push(colDef);
                            colDef.hide = colState.hide;
                        }
                    });
                });
                _.forEach(this.mergedColumnDefinitions, (colDef) => {
                    let colFound = false;
                    _.forEach(columnIndexes, (colIndex) => {
                        if (colDef.colId === colIndex.colId) {
                            colFound = true;
                        }
                    });
                    if (!colFound) {
                        columnIndexes.push(colDef);
                    }
                });
                this.mergedColumnDefinitions = columnIndexes;
            }
        } catch (e) {
            this.logger.error(e);
        }
    }

    _handleTableScroll(scroller): void {
        const scrollWidth = $('.ag-center-cols-container').width();
        const viewWidth = $('.ag-center-cols-viewport').width();
        const scrollableWidth = scrollWidth - viewWidth;
        if (scroller.left > 0) {
            $('.ag-pinned-left-cols-container').addClass('ag-pinned-left-shadow');
        } else {
            $('.ag-pinned-left-cols-container').removeClass('ag-pinned-left-shadow');
        }
        if (scroller.left < scrollableWidth - 2) {
            $('.ag-pinned-right-cols-container').addClass('ag-pinned-right-shadow');
        } else {
            $('.ag-pinned-right-cols-container').removeClass('ag-pinned-right-shadow');
        }
    }

    setDateRangeFilter(range) {
        let startDate;
        let label;
        let endDate = moment();
        let closeCalendar = true;
        this.selectedRange = range;
        switch (range) {
            case 'hour':
                startDate = moment().subtract(1, 'hours');
                label = 'Last Hour';
                break;
            case 'day':
                startDate = moment().subtract(24, 'hours');
                label = 'Last Day';
                break;
            case 'week':
                startDate = moment().subtract(7, 'days');
                label = 'Last Week';
                break;
            case 'month':
                startDate = moment().subtract(1, 'month');
                label = 'Last Month';
                break;
            case 'custom':
                if (!this.dateValue) {
                    return;
                }
                startDate = moment(this.dateValue[0]);
                if (this.dateValue[1] !== undefined && this.dateValue[1] !== null) {
                    endDate = moment(this.dateValue[1]);
                }
                label = 'Custom';
                closeCalendar = false;
                break;
            default:
                startDate = moment().subtract(24, 'hours');
                label = 'Last Day';
                break;
        }
        const startDateRange = encodeURIComponent('>=' + startDate.toISOString());
        const endDateRange = encodeURIComponent('<=' + endDate.toISOString());
        this.columnFilters[this.dateTimeColumn] = [startDateRange, endDateRange];

        if (closeCalendar) {
            this.calendar.toggle();
        }
        if (range !== 'custom') {
            this.dateValue = [startDate.toDate(), endDate.toDate()];
        }
        this.applyFilter(
            {},
            {
                columnId: this.dateTimeColumn,
                value: [startDateRange, endDateRange],
                label: label,
                filterName: 'Last Seen',
            }
        );
    }

    remToPx(remValue) {
      const rootFontSize: any = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
      return remValue * rootFontSize;
    };
}
