import {Component, Inject, Input, OnInit} from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import {Environment, ENVIRONMENT} from "@netfoundry-ui/shared/model";
import {delay} from "lodash";
import {BrandingModeService, FeatureService} from "@netfoundry-ui/shared/services";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
    selector: 'app-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent {
    @Input() isLoading = false;
    loadingIndicatorOptions: AnimationOptions = {
      path: '/assets/animations/Loader.json',
    };
    nfLoadingIndicatorOptions: AnimationOptions = {
        path: '/assets/animations/nf-loading-indicator.json',
    };
    neutralLoadingIndicatorOptions: AnimationOptions = {
      path: '/assets/animations/neutral-loading-indicator.json',
    };
    safeUrl: SafeResourceUrl;

    constructor(
      @Inject(ENVIRONMENT) public environment: Environment,
      private featureService: FeatureService,
      private sanitizer: DomSanitizer,
      public brandingService: BrandingModeService
    ) {

    }

    get showBrandedLoader() {
      return this.brandingService.orgBrandingInit && this.featureService.orgPreferencesInit;
    }

    get showNfLoadingIndicator() {
      return this.featureService.loadingIndicatorFile === '/assets/svgs/nf-loading-indicator.svg';
    }
}
