<link
    *ngIf="brandingService.darkModeEnabled && isLoggedIn && environment.defaultTheme !== 'dark'"
    [href]="darkModeUrl"
    rel="stylesheet"
    type="text/css"
/>
<link
  *ngIf="!brandingService.darkModeEnabled && isLoggedIn && environment.defaultTheme === 'dark'"
  [href]="lightModeUrl"
  rel="stylesheet"
  type="text/css"
/>
<main
    (mouseenter)="close()"
    [ngClass]="{
        open: isNavOpen && !pageIsUnauthed,
        shaded: isShaded,
        experimental: featureService.experimentalFeatures,
        newNav: !featureService.disableNewNavigation,
        noPad: !isLoggedIn || noPadding,
        fullScreen: hideSideBar,
        shadeBackground: isShadeBackground,
        darkmode: brandingService.darkModeEnabled && isLoggedIn,
        cardWarning: isLoggedIn && !billingEnabledService.hideCardWarning && billingEnabledService.isFourteenDayTrial
    }"
>
    <app-banner-message
        [isLoggedIn]="isLoggedIn"
        [message]="bannerMessage"
        [isVisible]="showBanner"
        [showSupportLink]="showSupportLink"
        [buttonText]="bannerButtonText"
        (buttonClicked)="bannerButtonClicked()"
        [showSpinner]="showBannerSpinner"
    ></app-banner-message>
    <div
        *ngIf="isLoggedIn && !billingEnabledService.hideCardWarning && billingEnabledService.isFourteenDayTrial"
        class="creditCardWarningContainer"
    >
        <app-credit-card-warning (close)="closeWarning()" class="creditCardWarning"></app-credit-card-warning>
    </div>

    <app-list-header
        *ngIf="isLoggedIn && isHeader"
        [actionTitle]="actionTitle"
        [breadcrumb]="breadcrumb"
        [hasAzureSubscription]="hasAzureSubscription"
        [network]="currentNetwork"
        [organization]="currentOrg"
        [resourceType]="resourceType"
        [shortBreadCrumbs]="shortBreadCrumbs"
        [tabs]="tabs"
        [title]="subtitle"
        [url]="url"
    ></app-list-header>
    <app-side-filter
        *ngIf="isFilterable && isLoggedIn && !hideLegacySearch"
        [isTimeSearchAvailable]="isTimeSearchAvailable"
        [isNotDialLogs]="isNotDialLogs"
    ></app-side-filter>
    <router-outlet></router-outlet>
</main>
<app-loading-indicator [isLoading]="appLoading && login.isLoggedIn"></app-loading-indicator>
<app-side-buttonbar (showGrowlersEvent)="showGrowlers()" *ngIf="isLoggedIn && !hideSideBar"></app-side-buttonbar>
<app-side-navigation
    (addEvent)="showWizard()"
    (mouseenter)="removeClose()"
    *ngIf="isLoggedIn && !hideSideBar && !pageIsUnauthed && featureService.disableNewNavigation"
></app-side-navigation>
<app-side-navigator (mouseenter)="removeClose()" *ngIf="isLoggedIn && !hideSideBar && !pageIsUnauthed && !featureService.disableNewNavigation"></app-side-navigator>
<app-page-tour *ngIf="isLoggedIn"></app-page-tour>
<app-growler></app-growler>
<app-recent-alerts
    (hideGrowlersEvent)="closeGrowlers()"
    *ngIf="isLoggedIn"
    [currentNetwork]="currentNetwork"
    [isOpen]="isGrowlersOpen"
></app-recent-alerts>

<div
    *ngIf="showQuickStart && this.featureService.showGettingStarted"
    class="QuickStart"
    routerLink="/getting-started"
></div>
<app-progress-bar *ngIf="apiService.tutorialActive"></app-progress-bar>
